.app-window {
	display: flex;
    flex-direction: column;
	width: 90%;
	max-width: 900px;
	height: 550px;
	transition: all 0.15s;
	background: $window-bg;
	color: $window-fg;
	border-radius: 6px;
	box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30),
				0 15px 12px rgba(0, 0, 0, 0.22);

	.app-window__header {
		position: relative;
		padding: 0 $window-padding;
		height: 40px;
		display: flex;
		align-items: center;

		.app-window__title {
			display: block;
			width: 100%;
			text-align: center;
			font-size: 0.75rem;
			color: $window-title-color;
		}

		.app-window__controls {
			position: absolute;
			left: $window-padding;
			display: flex;

			.app-window__controls--green {
				background-color: $window-control-green;
			}

			.app-window__controls--orange {
				background-color: $window-control-orange;
			}

			.app-window__controls--red {
				background-color: $window-control-red;
			}

			.app-window__controls-btn {
				cursor: pointer;
				outline: 0;
				border: 0;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				margin: 0 6px 0 0;
				padding: 0;
				-moz-appearance: none;
				-webkit-appearance: none;
				appearance: none;
			}

			.app-window__controls-btn::-moz-focus-inner {
				outline: 0 !important;
				border: 0 !important;
			}
		}
	}

	.app-window__body {
		height: calc(100% - 40px);
		overflow: hidden;
	}

	&.app-window--fullscreen {
		width: 100%;
		height: 100%;
		max-width: 100%;
		border-radius: 0;
	}
}

@media only screen and (max-width: 600px), (max-height: 600px) {
	.app-window {
		width: 100%;
		height: 100%;
		max-width: 100%;
		border-radius: 0;
	}

	.app-window .app-window__header {
		display: none;
	}

	.app-window .app-window__body {
		height: 100%;
	}

	.app-window .app-terminal .app-terminal__output {
		padding-top: $window-padding;
	}
}