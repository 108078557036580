$colors: (
	transparent: transparent,
	black: #000000,
	red: $terminal-red,
	green: $terminal-green,
	yellow: #ffff00,
	blue: $terminal-blue,
	magenta: $terminal-pink,
	cyan: $terminal-cyan,
	white: $terminal-white,
	bright-black: #000000,
	bright-red: $terminal-red,
	bright-green: $terminal-green,
	bright-yellow: #ffff00,
	bright-blue: $terminal-blue,
	bright-magenta: $terminal-pink,
	bright-cyan: $terminal-cyan,
	bright-white: $terminal-white,
);

@mixin color-modifiers {
	@each $name, $color in $colors {
		&--fg-#{$name} {
			color: #{$color};
		}

		&--bg-#{$name} {
			background: #{$color};
		}
	}
}

.app-terminal {
	height: 100%;
	font-size: 1rem;
	line-height: 1.5rem;

	.app-terminal__scroll-bar {
		height: 100%;
		overflow-x: hidden;

		.simplebar-track.simplebar-vertical {
			width: 14px;
		}

		.simplebar-track.simplebar-horizontal {
			height: 14px;
		}

		.simplebar-track .simplebar-scrollbar:before {
			border-radius: 6px;
			left: 4px;
			right: 4px;
			top: 4px;
			bottom: 4px;
			opacity: 0.4;
		}
	}

	.app-terminal__output {
		padding: 0 12px 12px;
		white-space: pre-wrap;
		word-break: break-all;
		font-size: 1rem;
		line-height: 1.5rem;

		span,
		div {
			min-height: 1.5rem;
		}
	}

	.app-terminal {
		@include color-modifiers;
	}

	.app-terminal--bold {
		font-weight: bold;
	}

	.app-terminal--light {
		font-weight: lighter;
	}

	.app-terminal--underline {
		text-decoration: underline;
	}

	.app-terminal--strike {
		text-decoration: line-through;
	}

	.app-terminal--italic {
		font-style: italic;
	}

	.app-terminal--bg-reset {
		background: $terminal-bg;
	}

	.app-terminal--fg-reset {
		color: $terminal-fg;
	}

	.app-terminal--inverse {
		&.app-terminal--bg-reset {
			background: $terminal-fg;
		}

		&.app-terminal--fg-reset {
			color: $terminal-bg;
		}
	}
}
