// Variables:
$bg: #fcfafc;

$terminal-red: #e06c75;
$terminal-green: #98c379;
$terminal-blue: #56b6c2;
$terminal-pink: #c678dd;
$terminal-cyan: #56b6c2;
$terminal-light-gray: #d0d0d0;
$terminal-medium-gray: #808080;
$terminal-white: #ffffff;
$terminal-fg: #abb2bf;
$terminal-bg: #282c34;

$window-title-color: #ffffff;
$window-bg: $terminal-bg;
$window-fg: $terminal-fg;

$window-padding: 12px;
$window-control-green: #45c547;
$window-control-orange: #ff9f0a;
$window-control-red: #ff443a;

// Base:
body {
	margin: 0;
	font-size: 16px;
	font-family: 'Fira Code', monospace, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}

#root {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.app {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

// Components:
@import './components/Window/Window';
@import './components/Terminal/Terminal';
@import './components/Terminal/Input/Input';

// Dependencies:
@import 'simplebar/dist/simplebar';